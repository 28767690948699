<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}活動`"
    width="880px"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="discount-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="event_name" style="grid-area: 1/1/2/3">
        <template #label>
          <span class="label">活動名稱</span>
        </template>
        <el-input v-model="ruleForm.event_name" placeholder="請輸入活動名稱" />
      </el-form-item>
      <el-form-item class="form-item" prop="description" style="grid-area: 1/3/4/5">
        <template #label>
          <span class="label">活動說明</span>
          <span class="green-hint">非必填</span>
        </template>
        <el-input
          v-model="ruleForm.description"
          type="textarea"
          :rows="11"
          placeholder="請輸入活動說明"
          resize="none"
        />
      </el-form-item>
      <!-- 門市 -->
      <el-form-item class="form-item" prop="store_list_type" style="grid-area: 2/1/3/3">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.store_list_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.store_list_type === 2"
          v-model="ruleForm.store_area"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.store_list_type === 3"
          v-model:file_url="ruleForm.store_file_url"
          v-model:file_name="ruleForm.store_file_name"
        ></upload-file>
      </el-form-item>
      <!-- 日期 -->
      <el-form-item class="form-item" prop="start_date" style="grid-area: 3/1/4/3">
        <template #label>
          <span class="label">活動日期</span>
        </template>
        <el-date-picker
          v-model="datetimeRange"
          type="datetimerange"
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :default-time="defaultTime"
        />
      </el-form-item>
      <!-- 活動條件 -->
      <div class="title" style="grid-area: 4/1/5/5">
        <span>活動條件</span>
        <el-divider />
      </div>
      <!-- 訂單來源、訂單類型、消費者類型 -->
      <el-form-item class="form-item" prop="source_platform" style="grid-area: 5/1/6/3">
        <template #label>
          <span class="label">訂單來源</span>
        </template>
        <el-select
          v-model="ruleForm.source_platform"
          multiple
          placeholder="請選擇來源"
          style="width: 100%"
          @change="handleSelectSource"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.source_platform, source_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in source_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="take_away_method" style="grid-area: 5/3/6/5">
        <template #label>
          <span class="label">訂單類型</span>
        </template>
        <el-select
          v-model="ruleForm.take_away_method"
          multiple
          placeholder="請選擇訂單類型"
          style="width: 100%"
          @change="handleSelectMethod"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.take_away_method, take_away_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option v-for="item in take_away_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="customer_type" style="grid-area: 6/1/7/3">
        <template #label>
          <span class="label">消費者類型</span>
        </template>
        <el-select
          v-model="ruleForm.customer_type"
          clearable
          placeholder="請選擇消費者類型"
          style="width: 100%"
          @change="handleSelectCustomer"
        >
          <el-option v-for="item in consumer_options_2" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <!-- App 會員 -->
      <el-form-item
        v-if="ruleForm.customer_type == 'app_member'"
        class="form-item radio-wrap"
        prop="card_member_type"
        style="grid-area: 6/3/7/5"
      >
        <template #label>
          <span class="label">App 會員</span>
          <el-radio-group
            v-model="ruleForm.app_member"
            style="display: inline-block; vertical-align: middle; margin-left: 15px; transform: translateY(1px)"
          >
            <el-radio v-for="item in app_member_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <upload-file
          v-if="ruleForm.app_member === 2"
          v-model:file_url="ruleForm.app_member_url"
          v-model:file_name="ruleForm.app_file_name"
        ></upload-file>
      </el-form-item>
      <!-- 旺卡會員 -->
      <el-form-item
        v-if="ruleForm.customer_type == 'card_member'"
        class="form-item radio-wrap"
        prop="card_member_type"
        style="grid-area: 6/3/7/5"
      >
        <template #label>
          <span class="label">旺卡會員</span>
          <el-radio-group v-model="ruleForm.card_member" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in card_member_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <upload-file
          v-if="ruleForm.card_member === 3"
          v-model:file_url="ruleForm.card_member_url"
          v-model:file_name="ruleForm.card_file_name"
        ></upload-file>
        <el-select
          v-if="ruleForm.card_member === 2"
          v-model="ruleForm.card_member_type"
          multiple
          placeholder="請選擇旺卡會員"
          style="width: 100%"
          @change="handleSelectCardMemberType"
        >
          <el-option
            v-if="!checkSelectAll(ruleForm.card_member_type, card_member_type_options)"
            label="全選"
            value="all"
          ></el-option>
          <el-option v-else label="取消全選" value="none"></el-option>
          <el-option
            v-for="item in card_member_type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!-- 活動限制 -->
      <div class="title" style="grid-area: 7/1/8/5">
        <span>活動限制</span>
        <el-radio-group v-model="ruleForm.activity_isrestricted" style="margin-left: 15px; transform: translateY(1px)">
          <el-radio :label="0">不限制</el-radio>
          <el-radio :label="1">限制（所有被勾選的條件都須被滿足）</el-radio>
        </el-radio-group>
        <el-divider />
      </div>
      <!-- 活動限制內容 -->
      <div v-if="ruleForm.activity_isrestricted === 1" style="grid-area: 8/1/9/5; display: flex">
        <el-form-item prop="activity_restriction_list">
          <el-checkbox-group v-model="activityRestrictionList">
            <el-checkbox label="minimumValue" style="margin-right: 0">
              <span class="activity-checkbox-label">滿額</span>
            </el-checkbox>
            <br />
            <el-checkbox label="purchaseValue" style="margin-right: 0">
              <span class="activity-checkbox-label">每達</span>
            </el-checkbox>
            <br />
            <el-checkbox label="eligibleItems">
              <span class="activity-checkbox-label">購買指定品項</span>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="activity-restriction-inputs">
          <el-form-item prop="minimumValue">
            <el-input
              v-model="ruleForm.activity_restriction.minimumValue"
              type="number"
              class="activity-checkbox-input"
            >
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="purchaseValue">
            <el-input
              v-model="ruleForm.activity_restriction.purchaseValue"
              type="number"
              class="activity-checkbox-input"
            >
              <template #append>元</template>
            </el-input>
            <span class="activity-checkbox-text">就可領取一個</span>
          </el-form-item>
          <el-form-item prop="eligibleItems">
            <el-cascader
              v-model="ruleForm.activity_restriction.eligibleItems"
              :options="getProductTree"
              :show-all-levels="false"
              :props="eligibleItems_props"
              placeholder="請選擇或輸入品項名稱"
              clearable
              filterable
              collapse-tags
              collapse-tags-tooltip
              size="default"
              class="eligibleItems-input"
            />
          </el-form-item>
        </div>
      </div>
      <!-- 來店禮兌換次數限制 -->
      <div class="title" style="grid-area: 9/1/10/5">
        <span>來店禮兌換次數限制</span>
        <el-radio-group v-model="ruleForm.redeem_isrestricted" style="margin-left: 15px; transform: translateY(1px)">
          <el-radio :label="0">不限制</el-radio>
          <el-radio :label="1" :disabled="ruleForm.customer_type == 'customer' || ruleForm.customer_type == 'all'">
            限制（僅能勾選任一限制）
          </el-radio>
        </el-radio-group>
        <el-divider />
      </div>
      <!-- 來店禮兌換次數限制內容 -->
      <div v-if="ruleForm.redeem_isrestricted === 1" style="grid-area: 10/1/11/5">
        <el-radio-group v-model="ruleForm.redeem_type">
          <!-- <el-form-item prop="redeem_type" :rules="[{validator: (rule, value, cb)=>validateRedeem_type(rule, value, cb, 'duration'), trigger: ''}]"> -->
          <el-form-item>
            <el-radio class="redeem-radio-label" label="duration">活動期間</el-radio>
            <el-input
              v-model="redeem_times.duration"
              @focus="changeRedeemTimes('duration')"
              type="number"
              class="redeem-radio-input"
              placeholder="請輸入次數"
            >
              <template #append>次</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-radio class="redeem-radio-label" label="daily">每天</el-radio>
            <el-input
              v-model="redeem_times.daily"
              @focus="changeRedeemTimes('daily')"
              type="number"
              class="redeem-radio-input"
              placeholder="請輸入次數"
            >
              <template #append>次</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-radio class="redeem-radio-label" label="weekly">每週</el-radio>
            <el-input
              v-model="redeem_times.weekly"
              @focus="changeRedeemTimes('weekly')"
              type="number"
              class="redeem-radio-input"
              placeholder="請輸入次數"
            >
              <template #append>次</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-radio class="redeem-radio-label" label="fixedWeek">固定週</el-radio>
            <el-select
              v-model="redeem_times.fixedWeek"
              clearable
              @focus="changeRedeemTimes('fixedWeek')"
              placeholder="請選擇星期"
              class="redeem-radio-input"
            >
              <el-option v-for="item in 5" :key="'week' + item" :label="item" :value="item" />
            </el-select>
            <span class="activity-checkbox-text">可領取一個</span>
          </el-form-item>
        </el-radio-group>
        <el-form-item prop="redeem_type"></el-form-item>
      </div>
      <!-- 活動文案: 來店禮標題、兌換品名稱 -->
      <div class="title" style="grid-area: 11/1/12/5">
        <span>活動文案</span>
        <el-divider />
      </div>
      <el-form-item class="form-item" prop="name" style="grid-area: 12/1/13/3">
        <template #label>
          <span class="label">來店禮標題</span>
          <span class="green-hint">顯示於 POS 提示視窗，上限 8 字</span>
        </template>
        <el-input v-model="ruleForm.name" placeholder="請輸入名稱" maxlength="8" show-word-limit />
      </el-form-item>
      <el-form-item class="form-item" prop="gift" style="grid-area: 13/1/14/5">
        <template #label>
          <span class="label">兌換品名稱</span>
          <span class="green-hint">顯示於 POS 視窗，上限 4 字，最多 5 項</span>
        </template>
        <div class="gift-inputs">
          <el-input
            v-model.trim="ruleForm.gift[0]"
            placeholder="請輸入商品名稱 1"
            style="grid-area: 1/1/2/2"
            maxlength="4"
            show-word-limit
          />
          <el-input
            v-model.trim="ruleForm.gift[1]"
            placeholder="請輸入商品名稱 2"
            style="grid-area: 1/2/2/3"
            maxlength="4"
            show-word-limit
          />
          <el-input
            v-model.trim="ruleForm.gift[2]"
            placeholder="請輸入商品名稱 3"
            style="grid-area: 1/3/2/4"
            maxlength="4"
            show-word-limit
          />
          <el-input
            v-model.trim="ruleForm.gift[3]"
            placeholder="請輸入商品名稱 4"
            style="grid-area: 2/1/3/2"
            maxlength="4"
            show-word-limit
          />
          <el-input
            v-model.trim="ruleForm.gift[4]"
            placeholder="請輸入商品名稱 5"
            style="grid-area: 2/2/3/3"
            maxlength="4"
            show-word-limit
          />
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormRef)" style="margin-left: 25px">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, watch, defineAsyncComponent, getCurrentInstance, reactive } from 'vue';
import { formatDateTime } from '@/utils/datetime';
import { useActions, useGetters } from '@/utils/mapStore'; // useGetters  useMutations
import {
  available_shop_type_options,
  area_options,
  source_options,
  take_away_options,
  consumer_options_2,
  app_member_options,
  card_member_type_options,
  card_member_options,
} from '@/utils/define';
export default {
  name: 'store-gift-dialog',
  components: {
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    const datetimeRange = ref(null);
    const activityRestrictionList = ref([]); // 活動限制多選
    const redeem_times = ref({
      duration: null,
      daily: null,
      weekly: null,
      fixedWeek: null,
    });
    const changeRedeemTimes = (str) => {
      ruleForm.value.redeem_type = str;
    };
    //取得各式選項
    const getProductTree = useGetters('common', ['getProductTree']);
    //商品樹
    const doGetProductTree = useActions('storeGift', ['doGetProductTree']);
    doGetProductTree();
    //商品樹props設定
    const eligibleItems_props = {
      value: 'id',
      label: 'name',
      children: 'product_list',
      multiple: true,
      emitPath: false,
    };
    //--------------------------------------------------------------------
    //設定初始值
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        // console.log('ruleForm.value ',ruleForm.value )

        //日期格式
        datetimeRange.value = [];
        ruleForm.value.start_date = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.start_date);
        ruleForm.value.end_date = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.end_date);
        datetimeRange.value.push(ruleForm.value.start_date);
        datetimeRange.value.push(ruleForm.value.end_date);

        // 活動限制多選的勾勾
        activityRestrictionList.value = Object.keys(ruleForm.value.activity_restriction);

        // 來店禮兌換次數限制
        redeem_times.value[ruleForm.value.redeem_type] = ruleForm.value.redeem_times;
      } else
        ruleForm.value = {
          event_id: null,
          event_name: '', // 活動名稱
          store_list_type: 1, // 門市
          store_area: [], // 指定區域
          store_file_url: '', // 門市清單csv檔
          store_file_name: '', // 檔名
          start_date: '', // 開始日期
          end_date: '', // 結束日期
          description: '', // 活動說明
          source_platform: [], // 訂單來源
          take_away_method: [], // 訂單類型
          customer_type: '', // 消費者類型
          app_member: 1, // app會員類別
          app_member_url: '', // 會員csv
          app_file_name: '', // 會員csv檔名
          card_member: 1, // 旺卡會員類別
          card_member_type: '', // 旺卡等級
          card_member_url: '', // 旺卡卡號csv
          card_file_name: '', // 旺卡csv檔名
          activity_isrestricted: 0, // 活動限制
          activity_restriction: {}, // 活動限制內容
          redeem_isrestricted: 0, // 兌換次數限制
          redeem_type: 'duration', // 兌換次數限制類別: "活動期間 duration" "每天 daily" "每週 weekly" "固定 fixedWeek"
          redeem_times: null, // 兌換次數
          name: '', // 來店禮標題
          gift: ['', '', '', '', ''], // 兌換品名稱
        };
    }
    //----------------------------------------------------------------------
    //處理來源跟類型的全選
    const handleSelectSource = (val) => {
      if (val.includes('all')) {
        ruleForm.value.source_platform = [];
        source_options.forEach((e) => {
          ruleForm.value.source_platform.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.source_platform = [];
      }
    };
    const handleSelectMethod = (val) => {
      if (val.includes('all')) {
        ruleForm.value.take_away_method = [];
        take_away_options.forEach((e) => {
          ruleForm.value.take_away_method.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.take_away_method = [];
      }
    };
    const handleSelectCardMemberType = (val) => {
      if (val.includes('all')) {
        ruleForm.value.card_member_type = [];
        card_member_type_options.forEach((e) => {
          ruleForm.value.card_member_type.push(e.value);
        });
      } else if (val.includes('none')) {
        ruleForm.value.card_member_type = [];
      }
    };
    const checkSelectAll = (select_arr, option_arr) => {
      if (select_arr.length === option_arr.length) return true;
      else return false;
    };
    //----------------------------------------------------------------------
    //radio切換-賦予初始值、清除錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    //切換門市
    watch(
      () => [ruleForm.value.store_list_type, ruleForm.value.store_area, ruleForm.value.store_file_url],
      () => {
        clearItemValidate(ruleFormRef.value, 'store_list_type');
      }
    );
    //----------------------------------------------------------------------
    //消費者類型為'一般消費者'，來店禮兌換次數限制為'不限制'
    const handleSelectCustomer = (val) => {
      if (val == 'customer') ruleForm.value.redeem_isrestricted = 0;
    };
    //--------------------------------------------------------------------
    //日期相關
    watch(
      () => datetimeRange.value,
      () => {
        if (!datetimeRange.value) {
          ruleForm.value.start_date = null;
          ruleForm.value.end_date = null;
        } else if (datetimeRange.value.length > 0) {
          ruleForm.value.start_date = datetimeRange.value[0];
          ruleForm.value.end_date = datetimeRange.value[1];
        }
      }
    );
    const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 0)];
    //--------------------------------------------------------------------
    //驗證
    const validateTime = (rule, value, callback) => {
      if (!datetimeRange.value) return callback(new Error('請選擇日期'));
      else callback();
    };
    const validateShop = (rule, value, callback) => {
      if (ruleForm.value.store_list_type != 2) ruleForm.value.store_area = [];
      if (ruleForm.value.store_list_type != 3) {
        ruleForm.value.store_file_name = '';
        ruleForm.value.store_file_url = '';
      }
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.store_area?.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.store_file_name || !ruleForm.value.store_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const validateGift = (rule, value, callback) => {
      let arr = ruleForm.value.gift.filter((data) => data != '');
      if (arr.length == 0) return callback(new Error('請輸入至少一項兌換品名稱'));
      else callback();
    };
    const validateActivityRestrictionList = (rule, value, callback) => {
      if (ruleForm.value.activity_isrestricted == 1 && activityRestrictionList.value.length == 0)
        callback(new Error('請勾選至少一項條件'));
      else callback();
    };
    const validateActivityRestriction = (rule, value, callback, str) => {
      if (activityRestrictionList.value.indexOf(str) == -1) callback();
      else {
        if (
          str == 'eligibleItems' &&
          (ruleForm.value.activity_restriction.eligibleItems == undefined ||
            ruleForm.value.activity_restriction.eligibleItems.length == 0)
        )
          callback(new Error());
        else if (ruleForm.value.activity_restriction[str] == null || ruleForm.value.activity_restriction[str] <= 0)
          callback(new Error());
        else callback();
      }
    };
    const validateRedeemType = (rule, value, callback) => {
      if (ruleForm.value.redeem_times == null || ruleForm.value.redeem_times <= 0) callback(new Error('請輸入次數'));
      else callback();
    };
    const rules = reactive({
      event_name: [{ required: true, message: '請輸入活動名稱', trigger: 'blur' }],
      start_date: [{ validator: validateTime, trigger: 'blur' }],
      store_list_type: [{ validator: validateShop, trigger: 'blur' }],
      source_platform: [{ required: true, message: '請選擇訂單來源', trigger: 'blur' }],
      take_away_method: [{ required: true, message: '請選擇訂單類型', trigger: 'blur' }],
      customer_type: [{ required: true, message: '請選擇消費者類型', trigger: 'blur' }],
      name: [{ required: true, message: '請輸入來店禮標題', trigger: 'blur' }],
      gift: [{ validator: validateGift, trigger: 'blur' }],
      redeem_type: [{ validator: validateRedeemType, trigger: 'blur' }],
      activity_restriction_list: [{ validator: validateActivityRestrictionList, trigger: 'change' }],
      minimumValue: [
        {
          validator: (rule, value, callback) => validateActivityRestriction(rule, value, callback, 'minimumValue'),
          trigger: 'blur',
        },
      ],
      purchaseValue: [
        {
          validator: (rule, value, callback) => validateActivityRestriction(rule, value, callback, 'purchaseValue'),
          trigger: 'blur',
        },
      ],
      eligibleItems: [
        {
          validator: (rule, value, callback) => validateActivityRestriction(rule, value, callback, 'eligibleItems'),
          trigger: 'blur',
        },
      ],
    });
    //--------------------------------------------------------------------
    //送出
    const { doAddStoreGift, doEditStoreGift } = useActions('storeGift', ['doAddStoreGift', 'doEditStoreGift']);
    const handleSubmit = async (form) => {
      try {
        if (!form) return;
        // 來店禮兌換次數
        ruleForm.value.redeem_times = redeem_times.value[ruleForm.value.redeem_type];
        // 活動限制內容
        let new_activity_restriction = {};
        activityRestrictionList.value.forEach((element) => {
          new_activity_restriction[element] = ruleForm.value.activity_restriction[element];
        });
        ruleForm.value.activity_restriction = new_activity_restriction;
        // 驗證
        await form.validate();
        // console.log('ruleForm--', ruleForm.value);
        if (cusProps.action === 'create') await doAddStoreGift(ruleForm.value);
        else await doEditStoreGift(ruleForm.value);
        proxy.$message({
          type: 'success',
          message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
        });
        submit(cusProps.action);
      } catch (e) {
        e;
      }
    };

    return {
      available_shop_type_options,
      area_options,
      source_options,
      take_away_options,
      consumer_options_2,
      app_member_options,
      card_member_type_options,
      card_member_options,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      datetimeRange,
      defaultTime,
      handleSelectSource,
      handleSelectMethod,
      handleSelectCustomer,
      handleSelectCardMemberType,
      checkSelectAll,
      rules,
      handleSubmit,
      activityRestrictionList,
      redeem_times,
      changeRedeemTimes,
      getProductTree,
      eligibleItems_props,
    };
  },
};
</script>
<style lang="scss" scoped>
.discount-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 25px;
}

.form-item {
  @include basic-form-item;
  min-height: auto;
}

.title {
  margin-top: 20px;

  span {
    color: $color-primary;
    font-size: 1.6rem;
  }
}

.dialog-footer {
  @include full-button;
}

.el-divider--horizontal {
  margin: 4px 0 24px;
}

:deep(.picture-label .el-radio__label) {
  display: flex;
  align-content: center;
}

.green-hint {
  margin-left: 8px;
  margin-bottom: 8px;
}

.form-item .label {
  display: inline-block;
  vertical-align: middle;
}

.gift-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 25px;
}
.gift-inputs .el-input {
  margin-bottom: 16px;
}
:deep(.radio-wrap .el-form-item__label) {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.radio-wrap .el-form-item__label .el-radio {
  height: 22px;
}
.el-checkbox {
  margin-bottom: 16px;
}
.activity-checkbox-label {
  width: 120px;
  display: inline-block;
  color: $color-text-light;
  font-size: 15px;
  font-weight: normal;
}
.activity-checkbox-input,
.redeem-radio-input,
:deep(.eligibleItems-input) {
  width: 355px;
}
.activity-checkbox-text {
  color: $color-text-light;
  font-size: 15px;
  margin-left: 23px;
  font-weight: normal;
}
.redeem-radio-label {
  width: 142px;
  display: inline-block;
  color: $color-text-light;
  font-size: 15px;
  font-weight: normal;
  margin-right: 0;
}
.activity-restriction-inputs .el-form-item {
  margin-bottom: 16px;
}
</style>
